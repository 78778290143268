import { useAdminUserStore } from "~/javascript/stores/admin/users";
const AdminUsersEdit = () => import("~/javascript/views/admin/users/AdminUsersEdit.vue");
const AdminUsersIndex = () => import("~/javascript/views/admin/users/AdminUsersIndex.vue");

const setShownUser = (to, from, next) => {
  const store = useAdminUserStore();
  store.loadUser(to.params.user_id);
  next();
};

export const userRoutes = [
  {
    path: "users",
    name: "Admin Users",
    children: [
      {
        path: "",
        component: AdminUsersIndex,
      },
      {
        path: ":user_id/edit",
        name: "Admin Edit User",
        beforeEnter: setShownUser,
        component: AdminUsersEdit,
      },
    ],
  },
];
