import { defineStore } from "pinia";
import { adminAxios as axios } from "~/javascript/backend";
import { useAuthStore } from "~/javascript/stores/auth";
import router from "~/javascript/router";
import { useAdminApiPathStore } from "~/javascript/stores/admin/api_paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  users: [],
  shownUser: {},
};

export const useAdminUserStore = defineStore({
  id: "admin_users",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    authStore() {
      return useAuthStore();
    },

    apiPathStore() {
      return useAdminApiPathStore();
    },

    usersPath() {
      return this.apiPathStore.usersPath;
    },

    usersCount(state) {
      return state.users.length;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    create(userObj) {
      const body = { user: userObj };
      axios
        .post("/users", body)
        .then((response) => {
          this.shownUser = response.data;
          router.push(this.usersPath);
          emitSuccess("User created");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    update(user) {
      axios
        .put(`${this.usersPath}/${user.id}`, { user: user })
        .then((response) => {
          emitSuccess("User updated");
          if (this.authStore.currentUser.id === user.id) {
            this.authStore.updateCurrentUser(response.data);
          }
          this.shownUser = response.data;
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },

    loadUsers() {
      axios.get(this.usersPath).then((response) => {
        this.users = response.data;
      });
    },

    loadUser(id) {
      axios.get(`${this.usersPath}/${id}`).then((response) => {
        this.shownUser = response.data;
      });
    },

    deleteUser(id) {
      axios.delete(`${this.usersPath}/${id}`).then((response) => {
        this.users = this.users.filter((object) => {
          return object.id !== id;
        });
      });
    },
  },
});
