import { defineStore } from "pinia";
import { useAdminSubscriptionPlanStore } from "~/javascript/stores/admin/subscription_plans";
import { useAdminWorkspaceStore } from "~/javascript/stores/admin/workspaces";

const ADMIN_ROUTE = "/admin";

export const useAdminPathStore = defineStore({
  id: "admin_paths",

  getters: {
    currentSubscriptionPlan() {
      return useAdminSubscriptionPlanStore().currentSubscriptionPlan;
    },

    currentWorkspace() {
      return useAdminWorkspaceStore().currentWorkspace;
    },

    adminPath() {
      return `${ADMIN_ROUTE}`;
    },

    dashboardPath() {
      return `${this.adminPath}/dashboard`;
    },

    subscriptionPlansPath() {
      return `${this.adminPath}/subscription_plans`;
    },

    subscriptionPlansCreatePath() {
      return `${this.subscriptionPlansPath}/create`;
    },

    subscriptionPlanPath() {
      return (id) => {
        const idParam = id || this.currentSubscriptionPlan?.id;
        return `${this.subscriptionPlansPath}/${idParam}`;
      };
    },

    subscriptionPlanEditPath() {
      return (id) => {
        const idParam = id || this.currentSubscriptionPlan?.id;
        return `${this.subscriptionPlansPath}/${idParam}/edit`;
      };
    },

    workspacesPath() {
      return `${this.adminPath}/workspaces`;
    },

    workspacePath() {
      return (id) => {
        const idParam = id || this.currentWorkspace?.id;
        return `${this.workspacesPath}/${idParam}`;
      };
    },

    workspacesIndexTablePath() {
      return `${this.workspacesPath}/index_table`;
    },

    usersPath() {
      return `${this.adminPath}/users`;
    },
  },
});
