import { usePurchaseStore } from "~/javascript/stores/purchases";
import {
  ACTION_CREATE,
  ACTION_INDEX,
  ACTION_SHOW,
  SCOPE_PURCHASES,
} from "~/javascript/can";
const PurchasesUpload = () =>
  import("~/javascript/views/websites/purchases/PurchasesUpload.vue");
const PurchasesIndex = () =>
  import("~/javascript/views/websites/purchases/PurchasesIndex.vue");
const PurchasesShow = () =>
  import("~/javascript/views/websites/purchases/PurchasesShow.vue");

const setCurrentPurchase = (to, from, next) => {
  const store = usePurchaseStore();
  if (store.currentWebsite?.id) {
    store.loadPurchase(to.params.purchase_id);
    next();
  } else {
    setTimeout(() => setCurrentPurchase(to, from, next), 100);
  }
};

export const purchasesRoutes = [
  {
    path: "purchases",
    name: "Purchases",
    children: [
      {
        path: "",
        component: PurchasesIndex,
        meta: {
          rbac: {
            action: ACTION_INDEX,
            scope: SCOPE_PURCHASES,
          },
        },
      },
      {
        path: "upload",
        name: "Bulk Import Purchases",
        component: PurchasesUpload,
        meta: {
          rbac: {
            action: ACTION_CREATE,
            scope: SCOPE_PURCHASES,
          },
        },
        },
      {
        path: ":purchase_id",
        name: "Purchase Details",
        beforeEnter: setCurrentPurchase,
        meta: {
          rbac: {
            action: ACTION_SHOW,
            scope: SCOPE_PURCHASES,
          },
        },
        children: [
          {
            path: "",
            component: PurchasesShow,
          },
        ],
      },
    ],
  },
];
