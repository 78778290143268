import { defineStore } from "pinia";

export const useAdminApiPathStore = defineStore({
  id: "admin_api_paths",

  getters: {
    adminPath() {
      return "/admin";
    },

    workspacesPath() {
      return `${this.adminPath}/workspaces`;
    },

    workspacesIndexTablePath() {
      return `${this.workspacesPath}/index_table`;
    },

    usersPath() {
      return "/users";
    },

    usersIndexTablePath() {
      return `/admin${this.usersPath}/index_table`;
    },
  },
});
