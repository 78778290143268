import { useContactStore } from "~/javascript/stores/contacts";
import { ACTION_CREATE, ACTION_INDEX, ACTION_SHOW, SCOPE_CONTACTS } from "~/javascript/can";
const ContactsCreate = () =>
  import("~/javascript/views/contacts/ContactsCreate.vue");
const ContactsEdit = () =>
  import("~/javascript/views/contacts/ContactsEdit.vue");
const ContactsIndex = () =>
  import("~/javascript/views/contacts/ContactsIndex.vue");
const ContactsShow = () =>
  import("~/javascript/views/contacts/ContactsShow.vue");

const ContactsUpload = () =>
  import("~/javascript/views/contacts/ContactsUpload.vue");

const setCurrentContact = (to, from, next) => {
  const store = useContactStore();
  store.loadContact(to.params.contact_id);
  next();
};

// this is the way
// TODO: convert other route sets to this pattern
// so we don't have as much weird monkeypatching in breadcrumbs.vue
export const contactsRoutes = [
  {
    path: "contacts",
    name: "Contacts",
    children: [
      {
        path: "",
        component: ContactsIndex,
        meta: {
          rbac: {
            action: ACTION_INDEX,
            scope: SCOPE_CONTACTS,
          },
        },
      },
      {
        path: "create",
        name: "New Contact",
        component: ContactsCreate,
        meta: {
          rbac: {
            action: ACTION_CREATE,
            scope: SCOPE_CONTACTS,
          },
        },
      },
      {
        path: "upload",
        name: "Bulk Import Contacts",
        component: ContactsUpload,
        meta: {
          rbac: {
            action: ACTION_CREATE,
            scope: SCOPE_CONTACTS,
          },
        },
      },
      {
        path: ":contact_id",
        name: "Contact",
        beforeEnter: setCurrentContact,
        meta: {
          rbac: {
            action: ACTION_SHOW,
            scope: SCOPE_CONTACTS,
          },
        },
        children: [
          {
            path: "",
            name: "Contact Home",
            component: ContactsShow,
          },
          {
            path: "edit",
            name: "Edit Contact",
            component: ContactsEdit,
          },
        ],
      },
    ],
  },
];
