import { defineStore } from "pinia";
import { adminAxios as axios } from "~/javascript/backend";
import { useAdminApiPathStore } from "~/javascript/stores/admin/api_paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  workspaces: [],
  currentWorkspace: {},
};

export const useAdminWorkspaceStore = defineStore({
  id: "admin_workspaces",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    apiPathStore() {
      const store = useAdminApiPathStore();
      return store;
    },

    workspacesPath() {
      return this.apiPathStore.workspacesPath;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentWorkspace(workspace) {
      this.currentWorkspace = workspace;
    },

    loadWorkspace(id) {
      axios.get(`${this.workspacesPath}/${id}`).then((response) => {
        this.setCurrentWorkspace(response.data);
      });
    },

    loadWorkspaces() {
      axios.get(this.workspacesPath).then((response) => {
        this.workspaces = response.data;
      });
    },

    update({ workspace, id }) {
      axios
        .put(`${this.workspacesPath}/${id}`, {
          workspace: workspace,
        })
        .then((response) => {
          this.setCurrentWorkspace(response.data);
          emitSuccess("Workspace updated");
        })
        .catch((error) => {
          emitError(error);
        });
    },
  },
});
