import { subscriptionPlanRoutes } from "~/javascript/router/admin/subscription_plans.js";
import { workspaceRoutes } from "~/javascript/router/admin/workspaces.js";
import { userRoutes } from "~/javascript/router/admin/user_routes.js";

const AdminDashboard = () =>
  import("~/javascript/views/admin/AdminDashboard.vue");

export const adminRoutes = [
  {
    path: "dashboard",
    name: "Admin Dashboard",
    component: AdminDashboard,
  },
  ...subscriptionPlanRoutes,
  ...workspaceRoutes,
  ...userRoutes,
];
