import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { emitError, emitSuccess } from "~/javascript/helpers";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { usePathStore } from "~/javascript/stores/paths";
import { useAuthStore } from "~/javascript/stores/auth";

const LOCAL_STORAGE_KEY = "current_workspace";

const DEFAULT_STATE = {
  workspace: {},
  workspaces: [],
  currentWorkspace: {},
};

export const useWorkspaceStore = defineStore({
  id: "workspaces",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentWorkspace: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
  }),

  actions: {
    resetState() {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      this.$state = structuredClone(DEFAULT_STATE);
    },

    loadUserWorkspaces() {
      axios.get("/workspaces").then((response) => {
        this.workspaces = response.data;
      });
    },

    async loadWorkspace(id) {
      return new Promise((resolve) => {
        axios.get(`workspaces/${id}`)
          .then((response) => {
            this.setCurrentWorkspace(response.data);
            resolve();
          })
          .catch((error) => {
            emitError(error);
            router.push(usePathStore().selectWorkspacePath);
          });
      });
    },

    async loadWorkspaceByIdentifier(identifier) {
      return new Promise((resolve) => {
        axios
          .get(useApiPathStore().workspaceByIdentifierPath(identifier))
          .then((response) => {
            this.setCurrentWorkspace(response.data.workspace);
            if (response.data.user) {
              const authStore = useAuthStore();
              authStore.updateCurrentUser(
                { 
                  ...authStore.getCurrentUser,
                  permissions: response.data.user.permissions,
                }
              );
            }
            resolve();
          })
          .catch((error) => {
            emitError(error);
            console.error(error);
            router.push(usePathStore().selectWorkspacePath);
          });
      });
    },

    joinWorkspaceByEmailDomain(workspaceId) {
      const data = { join: true };
      axios.put("/workspaces_join/" + workspaceId, data).then((response) => {
        this.setCurrentWorkspace(response.data);
        router.push(usePathStore().dashboardPath);
      });
    },

    createWorkspace(name, allowJoin) {
      const data = {
        workspace: {
          name: name,
          allow_join_by_email_domain: allowJoin.value,
        },
      };
      axios.post("/workspaces", data).then((response) => {
        this.setCurrentWorkspace(response.data);
      });
    },

    sendInvites(emails, workspaceId) {
      const data = {
        emails: emails,
        workspace_id: workspaceId,
      };
      axios.post("/workspaces/send_invites", data).then((response) => {
        Emitter.emit("inviteSent");
        emitSuccess("Invites sent");
      });
    },

    setCurrentWorkspace(workspace) {
      this.currentWorkspace = workspace;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(workspace));
    },
  },
});
