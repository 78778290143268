import { useLeadStore } from "~/javascript/stores/leads";
import {
  ACTION_CREATE,
  ACTION_INDEX,
  ACTION_SHOW,
  SCOPE_LEADS,
} from "~/javascript/can";
const LeadsUpload = () =>
  import("~/javascript/views/websites/leads/LeadsUpload.vue");
const LeadsIndex = () =>
  import("~/javascript/views/websites/leads/LeadsIndex.vue");
const LeadsShow = () =>
  import("~/javascript/views/websites/leads/LeadsShow.vue");

const setCurrentLead = (to, from, next) => {
  const store = useLeadStore();
  if (store.currentWebsite?.id) {
    store.loadLead(to.params.lead_id);
    next();
  } else {
    setTimeout(() => setCurrentLead(to, from, next), 100);
  }
};

export const leadsRoutes = [
  {
    path: "leads",
    name: "Leads",
    children: [
      {
        path: "",
        component: LeadsIndex,
        meta: {
          rbac: {
            action: ACTION_INDEX,
            scope: SCOPE_LEADS,
          },
        },
      },
      {
        path: "upload",
        name: "Bulk Import Leads",
        component: LeadsUpload,
        meta: {
          rbac: {
            action: ACTION_CREATE,
            scope: SCOPE_LEADS,
          },
        },
      },
      {
        path: ":lead_id",
        name: "Lead Details",
        beforeEnter: setCurrentLead,
        meta: {
          rbac: {
            action: ACTION_SHOW,
            scope: SCOPE_LEADS,
          },
        },
        children: [
          {
            path: "",
            component: LeadsShow,
          },
        ],
      },
    ],
  },
];
